.forexChipContainer{
    padding: 3px 8px;
    background-color: #e7eef2;
    border-radius: 4px;
    border: 1px solid grey;
    width: auto;
    color: #21364f;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    font-size: 12px;
    width: max-content;
}
.forexDropIconDiv{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: lightgray;
    padding: 5px;
    border-radius: 4px;
    cursor: pointer;
}
.forexDropIconDiv:hover{
 opacity: 0.6;
}
.forexSelectContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #000;
    padding: 0px 0px 0px 0px ;
    border-radius: 5px;
    margin-top: 10px;
}
.forexDropDownShowContainer{
    width: calc(90% - 55px);
    border: 1px solid #000;
    /* height: 200px; */
    padding: 20px;
    position: absolute;
    z-index: 9;
    background-color: #fff;
    border-radius: 8px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1), /* Main shadow */
              0 6px 20px rgba(0, 0, 0, 0.1); /* Additional subtle shadow */
  transition: box-shadow 0.3s ease-in-out; /* Smooth transition for shadow */
}


.table-container {
    width: 96%;
    margin: 20px auto;
    overflow-x: auto;
  }
  
  .data-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .data-table th, .data-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    min-width: 200px;
  }
  
  .data-table th {
    /* background-color: #f2f2f2; */
    font-weight: bold;
  }
  
  .data-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .data-table tr:hover {
    background-color: #ddd;
  }
  .tableFixHead          { overflow: auto;     min-height: 300px;
    max-height: calc(100% - 210px); margin-top: 20; width: 97%; margin: 20px auto 0; }
.tableFixHead thead th { position: sticky; top: 0; z-index: 1; }

/* Just common table stuff. Really. */
table  { border-collapse: collapse; width: 100%; overflow-x: scroll; }
th, td { padding: 5px !important;  }
th     { background:#eee; }

.tableFixHead::-webkit-scrollbar {
  width: 10px;
}


.tableFixHead::-webkit-scrollbar {
  width: 10px;
  background-color: #E4E8F4;
}


.tableFixHead::-webkit-scrollbar-thumb {
  background-color: #3068C8;
  
  border-radius: 5px;
  margin: 10px;
}
 .tableFixHead::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
}
.tableFixHead:hover::-webkit-scrollbar-corner {
  width: 8px;
  background-color: #E4E8F4;
  padding: 4;
}

.clickForListPopUpContainer::-webkit-scrollbar {
  width: 6px;
}


.clickForListPopUpContainer::-webkit-scrollbar {
  width: 6px;
  background-color: #E4E8F4;
}


.clickForListPopUpContainer::-webkit-scrollbar-thumb {
  background-color: #3068C8;
  
  border-radius: 5px;
  margin: 10px;
}
 .clickForListPopUpContainer::-webkit-scrollbar-thumb:hover {
  background-color: #7B7F88;
  border-radius: 5px;
  margin: 2px;;
}
.clickForListPopUpContainer:hover::-webkit-scrollbar-corner {
  width: 8px;
  background-color: #E4E8F4;
  padding: 4;
}


.clickForList{
  width: 180px;
  padding: 0px 3px 0px 6px;
  border: 1px solid black;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.clickForList:hover{
opacity: 0.7;
}
.clickForListPopUpContainer{
  width: 180px;
  height: 300px;
  background-color: #fff;
  border-radius: 5px;
  border: 1px solid #000;
  position: absolute;
  z-index: 9;
  /* padding: 0px 10px; */
  /* overflow-y: scroll; */
}
.clickForListGridContainer{
  display: grid;
    /* grid-template-columns: repeat(4, 1fr); */
    /* gap: 10px; */
    justify-items: flex-start;
    /* margin-top: 15px; */
    margin-bottom: 15px;
}
.ishide
{
  /* background: green!important; */
  display: none;
}
.noHide
{

}


.pagingNumber {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #000;
  padding-top: 1.5px;
}

.pagingNumberSelected {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: #3068c8;
  color: #fff;
  opacity: 1;
  border-radius: 5px;
  padding-top: 1.5px;
}

.tabs-container {
  display: flex;
  border: 1px solid #ccc;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 20px;
}

.tab-button {
  flex: 1;
  padding: 10px;
  border: none;
  background-color: white;
  color: #555;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  width: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tab-button.active {
  background-color: #e0e0e0; /* Gray background for active tab */
  color: black;
  font-weight: bold;
}

.tab-button:not(.active):hover {
  background-color: #f5f5f5; /* Hover effect */
}

.tab-button:first-child {
  border-right: 1px solid #ccc; /* Add border for separation */
}

.tab-button:last-child {
  border-left: 1px solid #ccc; /* Add border for separation */
}

.tab-button + .tab-button {
  border-left: 1px solid #ccc;
}


.exportFileBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: rgb(48, 104, 200);
  /* border: 0.1px solid grey; */
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
}
.exportFileBtn:hover{
  background-color: rgba(48, 104, 200, 0.9);
}

.groupThHead:hover{
  background-color: rgba(0, 0, 0, 0.05);
  color: blue;
  text-decoration: underline;
}

.syncBtn{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(48, 104, 200);
  /* border: 0.1px solid grey; */
  border-radius: 5px;
  padding: 8px 15px;
  cursor: pointer;
  color: white;
  font-family: 'ManRopeSemiBold';
}
.syncBtn:hover{
  background-color: rgba(48, 104, 200, 0.9);
}











/* Customize event colors based on profit/loss */
.e-schedule .e-all-day-cells .e-subject {
  font-size: 14px;
  font-weight: bold;
}

.e-subject {
  font-weight: bold;
}

/* Profit events in green */
.e-schedule .e-appointment.e-event[aria-label*="Profit"] {
  background-color: #d4edda;
  border-color: #28a745;
  color: #155724;
}

/* Loss events in red */
.e-schedule .e-appointment.e-event[aria-label*="Loss"] {
  background-color: #f8d7da;
  border-color: #dc3545;
  color: #721c24;
}
.e-toolbar-right{
  display: none !important;
}
.e-tbar-pos{
background-color: #fff !important;
}
.e-toolbar .e-tbar-btn{
  background-color: #fff !important;
}
.e-appointment{
 /* background-color: #caebe2 !important;*/
  height: 60px !important;
  color: #000 !important;
}
.customTemplate{
  background-color: red;
}