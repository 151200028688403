@import '../node_modules/@syncfusion/ej2-base/styles/material.css';
@import '../node_modules/@syncfusion/ej2-buttons/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-calendars/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-inputs/styles/material.css';  
@import '../node_modules/@syncfusion/ej2-navigations/styles/material.css';
@import '../node_modules/@syncfusion/ej2-popups/styles/material.css';
@import '../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css';
/* @import "../node_modules/@syncfusion/ej2-react-grids/styles/material.css"; */
@import url('https://cdn.syncfusion.com/ej2/material.css');
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-calendars/styles/material.css";
@import "../node_modules/@syncfusion/ej2-dropdowns/styles/material.css";
@import "../node_modules/@syncfusion/ej2-inputs/styles/material.css";
@import "../node_modules/@syncfusion/ej2-lists/styles/material.css";
@import "../node_modules/@syncfusion/ej2-navigations/styles/material.css";
@import "../node_modules/@syncfusion/ej2-popups/styles/material.css";
@import "../node_modules/@syncfusion/ej2-splitbuttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-schedule/styles/material.css";




body{
    background: #fdfdfd !important;
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif !important; */
    font-family: 'ManRope' !important;
}
@media only screen and (max-width: 768px) {
  body{
    /* font-family: 'HelveticaNormal', Tahoma, Geneva, Verdana, sans-serif  !important; */
    font-family: 'ManRope' !important;
}
}
#webpack-dev-server-client-overlay{
    display: none !important;
}
.react-confirm-alert-overlay{
    background: rgba(0, 0, 0, 0.4) !important;
}
.react-confirm-alert-button-group {
    display: flex;
    justify-content: center;
    align-items: center;
    gap:20px;
}
.react-confirm-alert-button-group > button{
    margin-right: 0px !important;
    background-color: #5E97C3 !important;
    font-weight: 600 !important;
    display: flex;
    justify-content: center;
    align-items: center;
}
.errorPageCrossIcon{
    padding: 12px;
    background: rgba(0,0,0,0.1) !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.errorPageCrossIcon:hover{
    padding: 12px;
    background: rgba(0,0,0,0.25) !important;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}
@font-face {
    font-family: "Segoe UI";   /*Can be any text*/
    src: local("Segoe"),
      url("./Fonts/Segoe\ UI.ttf") format("truetype");
  }
@font-face {
    font-family: "HelveticaNormal";   /*Can be any text*/
    src: local("HelveticaNormal"),
      url("./Fonts/HelveticaNormal.ttf") format("truetype");
  }
@font-face {
    font-family: "Helvetica";   /*Can be any text*/
    src: local("Helvetica"),
      url("./Fonts/Helvetica.ttf") format("truetype");
  }
@font-face {
    font-family: "HelveticaSemiBold";   /*Can be any text*/
    src: local("HelveticaSemiBold"),
      url("./Fonts/Helvetica\ semiBold.ttf") format("truetype");
  }
@font-face {
    font-family: "HelveticaNarrowBold";   /*Can be any text*/
    src: local("HelveticaNarrowBold"),
      url("./Fonts/HelveticaCE-NarrowBold.ttf") format("truetype");
  }



@font-face {
    font-family: "ManRope";   /*Can be any text*/
    src: local("ManRope"),
      url("./Fonts/Manrope/static/Manrope-Regular.ttf") format("truetype");
  }
@font-face {
    font-family: "ManRopeSemiBold";   /*Can be any text*/
    src: local("ManRopeSemiBold"),
      url("./Fonts/Manrope/static/Manrope-SemiBold.ttf") format("truetype");
  }
@font-face {
    font-family: "ManRopeBold";   /*Can be any text*/
    src: local("ManRopeBold"),
      url("./Fonts/Manrope/static/Manrope-Bold.ttf") format("truetype");
  }

  .customCursor {
    cursor: url('./assets/not-allowed.png'), auto !important;
  }
  @media print {
    body * {
      visibility: hidden;
    }
  
    #print, #print * {
      visibility: visible;
      overflow: visible !important;
    }
  
    #print {
      position: absolute;
      left: 0;
      top: 0;
    }
    #taskBar, #taskBar * {
        visibility: hidden;
      }
    #more, #more * {
        visibility: hidden;
      }
    #listPopper, #listPopper * {
        visibility: hidden;
      }
  }
  ._3curk{
    z-index: 99999 !important;
  }